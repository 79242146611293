<!-- <app-header-non-fixed></app-header-non-fixed> -->

<!-- ---------- image main booking / payment ----------- -->
<!-- <div class="bg-payment-page">
  <div class="centered_">
    <span class="tittle-page" >PAYMENT</span>
   </div>
</div> -->
<!-- ----------- Payment Form ----------- -->
<div class="container body-navbar">
  <span class="font-3r color-red-pastel"><b>PAYMENT</b></span>
  <hr class="hr-1"><br>
  <div class="row">
    <div class="col-sm-12 col-md-7 col-lg-7 col-xl-7">
      <div class="review-order">
        <div class="shadow p-3 mb-5 bg-white rounded">
            <div class="review-order-form">
              <span class="title-review-order">REVIEW ORDER</span>
                <hr>
                <div class="review-tour">
                  <table>
                    <tr *ngIf="transaction?.event_code">
                      <td class="td-1">Event code </td>
                      <td class="td-2"> : </td>
                      <td class="color-2"> {{transaction?.event_code}}</td>
                    </tr>
                    <tr>
                      <td class="td-1">Event name </td>
                      <td class="td-2"> : </td>
                      <td class="color-2"> {{transaction?.event_title}}</td>
                    </tr>
                    <tr>
                      <td class="td-1">Travel date </td>
                      <td class="td-2"> : </td>
                      <td class="color-2">{{transaction?.event_travel_date}}</td>
                    </tr>
                    <tr>
                      <td class="td-1">Type </td>
                      <td class="td-2"> : </td>
                      <td class="color-2"> {{transaction?.privacy}}</td>
                    </tr>
                    <tr>
                      <td class="td-1">Pickup time </td>
                      <td class="td-2"> : </td>
                      <td class="color-2"> {{transaction?.pickup_time}}</td>
                    </tr>
                  </table>
                </div>
                <hr>
                <div class="review-guest">
                  <table>
                    <tr>
                      <td class="td-1">Name </td>
                      <td class="td-2"> : </td>
                      <td class="color-2"> {{transaction?.fullname}}</td>
                    </tr>
                    <tr>
                      <td class="td-1">Email </td>
                      <td class="td-2"> : </td>
                      <td class="color-2"> {{transaction?.email}}</td>
                    </tr>
                    <tr>
                      <td class="td-1">Phone </td>
                      <td class="td-2"> : </td>
                      <td class="color-2"> {{transaction?.phone}}</td>
                    </tr>
                    <tr *ngIf="transaction?.hotel && false">
                      <td class="td-1">Hotel </td>
                      <td class="td-2"> : </td>
                      <td class="color-2"> {{transaction?.hotel}}</td>
                    </tr>
                  </table>
                </div>
                <hr>
                <div class="review-price">
                  <table style="width:100%">
                    <tr>
                      <th style="width:50%">Person</th>
                      <th><span class="float-light">Pax</span></th>
                      <th><span class="float-light">Price (THB)</span></th>
                      <th><span class="float-light">Amount (THB)</span></th>
                    </tr>
                    <tr>
                      <td>Adult</td>
                      <td><span class="float-light">{{transaction?.adult_pax}}</span></td>
                      <td><span class="float-light">{{transaction?.adult_price | number:'1.2-2'}}</span></td>
                      <td><span class="float-light">{{transaction?.total_adult_price | number:'1.2-2'}}</span></td>
                    </tr>
                    <tr>
                      <td>Child</td>
                      <td><span class="float-light">{{transaction?.child_pax}}</span></td>
                      <td><span class="float-light">{{transaction?.child_price | number:'1.2-2'}}</span></td>
                      <td><span class="float-light">{{transaction?.total_child_price | number:'1.2-2'}}</span></td>
                    </tr>
                    <tr>
                      <td>Child <small>(under 8 years old)</small></td>
                      <td><span class="float-light">{{transaction?.infant_pax}}</span></td>
                      <td><span class="float-light">{{ 0 | number:'1.2-2'}}</span></td>
                      <td><span class="float-light">{{ 0 | number:'1.2-2'}}</span></td>
                    </tr>

                    <!-- Discount -->
                    <ng-container *ngIf="transaction.discount > 0">
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <span class="float-light">
                            <b>{{ transaction.amount + transaction.discount | number:'1.2-2'}}</b>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td class="discount p-t-5">
                          Promotion code : 
                          <span class="f-w-500">{{ transaction.discount_code }}</span>
                        </td>
                        <td class="discount p-t-5"></td>
                        <td class="discount p-t-5">
                          <span class="float-light">
                            Discount {{ transaction.discount_rate }}
                          </span>
                        </td>
                        <td class="discount p-t-5">
                          <span class="float-light">
                            -{{ transaction.discount | number:'1.2-2'}}
                          </span>
                        </td>
                      </tr>
                    </ng-container>

                    <tr>
                      <td class="p-t-15"></td>
                      <td class="p-t-15"></td>
                      <td class="review-text-total p-t-15"><span class="float-light"><b>TOTAL</b></span></td>
                      <td class="review-text-total p-t-15"><span class="float-light"><b>{{ transaction?.amount | number:'1.2-2'}}</b></span></td>
                    </tr>
                  </table>
                </div>
            </div>
        </div>
      </div>
   </div>
   <div class="col-sm-12 col-md-5 col-lg-5 col-xl-5">
      <div class="remark-form">
        <!-- <span>
          *The listed price does not include a 500THB & 1,000THB surcharge for pick
          up and drop off in hotel outside the city centre (6 km. from the three king monument).
        </span><br><br> -->
        <span style="color:red">
          Noted : After your payment via Paypal, the system will redirect to our website.
          We will get back the confirmation to you via email.
        </span>
      </div>
      <hr>
      <ngx-paypal [config]="payPalConfig" [registerscript]="true"></ngx-paypal>
      <br>
      <div *ngIf="isReload == true" class="reload-icon"><img class="icon-reload-gif" src="./assets/images/payment/Reload-1s-200px.gif"></div>
      <div *ngIf="isWaiting == true" class="message-wait" >Your Payment is being processed, Please do not close this page</div>
   </div>
  </div>
</div>

<!-- ---- Footer ---- -->
<!-- <app-footer></app-footer> -->
